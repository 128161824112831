import React, { memo } from 'react';
import ContactA from './blocks/Contact/ContactA';
import HeadingA from './blocks/Heading/HeadingA';
import ObjectiveA from './blocks/Objective/ObjectiveA';
import PageContext from '../contexts/PageContext';

const London2 = ({ data }) => (
  <PageContext.Provider value={{ data, heading: HeadingA }}>
    <div
      id="page"
      className="p-8 rounded"
      style={{
        fontFamily: data.metadata.font,
        color: data.metadata.colors.text,
        backgroundColor: data.metadata.colors.background,
      }}
    >
      <div className="text-sm font-medium tracking-wide mt-12">
        <strong>To</strong>
      </div>
      {data.companydetails.managerName && (
        <span className="text-sm">{data.companydetails.managerName}</span>
      )}
      <br />
      {data.companydetails.companyName && (
        <span className="text-sm">{data.companydetails.companyName}</span>
      )}
      <div className="text-sm font-medium tracking-wide mt-12">
        <ContactA />
      </div>
      <div className="grid grid-cols-12 gap-8 mt-5">
        <div className="col-span-8">
          <div className="grid gap-4">
            {data.letterdetails.body && <ObjectiveA />}
          </div>
        </div>
      </div>
      <div className="rounded flex flex-col justify-center">
        <div className="flex flex-col justify-center">
          <div className="text-sm font-medium tracking-wide mt-5">
            <strong>
              {data.personaldetails.firstName} {data.personaldetails.lastName}
            </strong>
          </div>
        </div>
      </div>
    </div>
  </PageContext.Provider>
);

export default memo(London2);
