import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import Heading from '../../../shared/Heading';
import ClInput from '../../../shared/ClInput';

const CompanyDetails = ({ id }) => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="flex justify-between pt-3">
        <Heading id={id} />
      </div>

      <ClInput
        name="heading"
        label={t('builder.sections.heading')}
        path={`${id}.heading`}
      />

      <div className="grid grid-cols-2 gap-6">
        <ClInput
          name="companyName"
          label={t('builder.profile.companyName')}
          path="companydetails.companyName"
        />
        <ClInput
          name="managerName"
          label={t('builder.profile.managerName')}
          path="companydetails.managerName"
        />
      </div>
    </section>
  );
};

export default memo(CompanyDetails);
