import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { memo } from 'react';
import cx from 'classnames';
import * as styles from './Templates.module.css';
import { handleKeyUp } from '../../../../utils';
import { useDispatch, useSelector } from '../../../../contexts/ResumeContext';
import Heading from '../../../shared/Heading';
import templateOptions2 from '../../../../data/templateOptions2';
import Button from '../../../shared/Button';

const Templates = ({ id, closeClassName, onClick }) => {
  const dispatch = useDispatch();
  const template = useSelector('metadata.template');

  const previews = useStaticQuery(graphql`
    query {
      stockholm2: file(relativePath: { eq: "templates2/stockholm2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
      newyork2: file(relativePath: { eq: "templates2/newyork2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
      vienna2: file(relativePath: { eq: "templates2/vienna2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
      sydney2: file(relativePath: { eq: "templates2/sydney2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
      london2: file(relativePath: { eq: "templates2/london2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
      dublin2: file(relativePath: { eq: "templates2/dublin2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
    }
  `);

  const handleClick = (value) => {
    dispatch({
      type: 'on_input2',
      payload: {
        path: 'metadata.template',
        value,
      },
    });
  };

  return (
    <section>
      <div className="flex justify-between pt-3">
        <Heading id={id} />
        <Button outline className={closeClassName} onClick={onClick}>
          Close
        </Button>
      </div>

      <div className="grid grid-cols-2 gap-8">
        {templateOptions2.map((x) => (
          <div
            key={x.id}
            tabIndex="0"
            role="button"
            onKeyUp={(e) => handleKeyUp(e, () => handleClick(x.id))}
            onClick={() => handleClick(x.id)}
            className={cx(styles.template, {
              [styles.selected]: template === x.id,
            })}
          >
            <GatsbyImage
              alt={x.name}
              image={previews[x.id].childImageSharp.gatsbyImageData}
            />
            <span>{x.name}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default memo(Templates);
