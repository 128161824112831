import { Element } from 'react-scroll';
import React, { Fragment, memo, useRef, useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FaRegFilePdf } from 'react-icons/fa';
import * as styles from './LeftSidebar.module.css';
import Artboard from '../center/CLArtboard';
import RightSettings from '../right/RightSettings';
// import LeftNavbar from './LeftNavbar';
import LetterDetails from './sections/LetterDetails';
import PersonalDetails from './sections/PersonalDetails';
import sections from '../../../data/leftclSections';
import CompanyDetails from './sections/CompanyDetails';

const getComponent = (id) => {
  switch (id) {
    case 'personalDetails':
      return PersonalDetails;
    case 'companyDetails':
      return CompanyDetails;
    case 'letterDetails':
      return LetterDetails;
    default:
      throw new Error();
  }
};

const SidebarSection = ({ id, event, closeClassName, onClick }) => {
  const Component = getComponent(id);

  return (
    <Fragment key={id}>
      <Element name={id}>
        <Component
          id={id}
          event={event}
          closeClassName={closeClassName}
          onClick={onClick}
        />
      </Element>
      <hr />
    </Fragment>
  );
};

const LeftSidebar = ({ name }) => {
  const ref = useRef(null);
  const [showPreview, setShowPreview] = useState(0);

  /* function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  } */

  /* const { width } = useWindowDimensions();

  const handleOpenClick = () => {
    const leftSections = ref.current;
    if (!leftSections.className.includes(styles.showleftsect))
      leftSections.className += ` ${styles.showleftsect}`;
    leftSections.style.width = `${width - 140}px`;
  }; */

  const onGoBack = () => {
    if (showPreview === 2) setShowPreview(1);
    else setShowPreview(0);
  };

  const onSettings = () => {
    setShowPreview(2);
  };

  useEffect(() => {
    // handleOpenClick();
  }, []);

  const handleCloseClick = () => {
    const leftSections = ref.current;
    if (leftSections.className.includes(styles.showleftsect))
      leftSections.className = styles.container;
  };

  const handlePreview = () => {
    setShowPreview(1);
  };

  if (showPreview === 1) {
    return <Artboard onGoBack={onGoBack} onSettings={onSettings} />;
  }

  if (showPreview === 2) {
    return <RightSettings onGoBack={onGoBack} />;
  }

  return (
    <div className="flex flex-col">
      {/* <LeftNavbar onClick={(e) => handleOpenClick(e)} /> */}
      <h1 className={styles.h1}>{name}</h1>
      <div className="flex justify-center mb-2">
        <StaticImage
          src="../../../../static/images/us.svg"
          alt="us"
          height={13}
        />
        <span className={styles.lang}>English</span>
      </div>
      <div ref={ref} id="LeftSidebar" className={styles.container}>
        {sections.map((x) => (
          <SidebarSection
            id={x.id}
            event={x.event}
            closeClassName={styles.btnclose}
            onClick={(e) => {
              handleCloseClick(x, e);
            }}
          />
        ))}
      </div>
      <button
        id="download"
        className={`${styles.download_button} ${styles.btnclose}`}
        onClick={handlePreview}
      >
        Preview & Download
        <FaRegFilePdf size={20} className={styles.pdf_icon} />
      </button>
    </div>
  );
};

export default memo(LeftSidebar);
