import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import { hasAddress } from '../../../utils';
import PageContext from '../../../contexts/PageContext';

const ContactItem = ({ value, link }) =>
  value ? (
    <div className="flex items-center">
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span className="font-medium break-all">{value}</span>
        </a>
      ) : (
        <span className="font-medium break-all">{value}</span>
      )}
    </div>
  ) : null;

const ContactA = () => {
  const { t } = useTranslation();
  const { data } = useContext(PageContext);

  return (
    <div className="text-xs grid gap-2">
      <ContactItem
        label={t('shared.forms.phone')}
        value={`${[
          data.personaldetails.firstName,
          data.personaldetails.lastName,
        ]
          .filter(Boolean)
          .join(' ')}`}
      />
      <ContactItem
        label={t('shared.forms.subtitle')}
        value={data.personaldetails.subtitle}
      />
      {hasAddress(data.personaldetails.address) && (
        <ContactItem
          label={t('shared.forms.address')}
          value={data.personaldetails.address.line1}
        />
      )}
      <ContactItem
        label={t('shared.forms.phone')}
        value={data.personaldetails.phone}
        link={`tel:${data.personaldetails.phone}`}
      />
      <ContactItem
        label={t('shared.forms.email')}
        value={data.personaldetails.email}
        link={`mailto:${data.personaldetails.email}`}
      />
    </div>
  );
};

export default memo(ContactA);
