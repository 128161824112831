import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { BsChevronLeft, BsGear } from 'react-icons/bs';
import React, { memo, useState, useRef } from 'react';
import * as styles from './CLArtboard.module.css';
import { useSelector } from '../../../contexts/ResumeContext';
import Button from '../../shared/Button';
import Stockholm2 from '../../../templates2/Stockholm2';
import Newyork2 from '../../../templates2/Newyork2';
import Vienna2 from '../../../templates2/Vienna2';
import Sydney2 from '../../../templates2/Sydney2';
import London2 from '../../../templates2/London2';
import Dublin2 from '../../../templates2/Dublin2';

const CLArtboard = ({ onGoBack, onSettings }) => {
  const state = useSelector();
  const { t } = useTranslation();
  const { id, name, metadata } = state;
  const { template } = metadata;
  const ref = useRef(null);
  const [zoom, setZoom] = useState(0.45);

  const handleDownloadPdf = () => {
    if (typeof window !== `undefined`) {
      window.location.href = `https://resumesmadehere.com/purchase/?id=${id}&type=cover_letter`;
    }
  };

  const handleZoomIn = () => {
    let zoomIn = zoom === 0.45 ? 0.5 : zoom;
    zoomIn += 0.25;
    if (zoomIn > 2) zoomIn = 2;
    setZoom(zoomIn);
    ref.current.style.zoom = zoomIn;
  };

  const handleZoomOut = () => {
    let zoomOut = zoom - 0.25;
    if (zoomOut <= 0.5) zoomOut = 0.45;
    setZoom(zoomOut);
    ref.current.style.zoom = zoomOut;
  };

  return (
    <>
      <Helmet>
        <title>
          {name} | {t('shared.appName')}
        </title>
        <link
          rel="canonical"
          href={`https://app.resumesmadehere.com/app/builder/${id}`}
        />
      </Helmet>

      <div className="flex justify-around items-center mt-5">
        <button className={styles.btnclose} onClick={onGoBack}>
          <BsChevronLeft size="24" />
        </button>
        <Button
          icon={FaDownload}
          className={styles.button}
          onClick={handleDownloadPdf}
        >
          {t('modals.export.downloadPDF.heading')}
        </Button>
        <button className={styles.btnclose} onClick={onSettings}>
          <BsGear size="24" />
        </button>
      </div>
      <div ref={ref} className={styles.preview}>
        <div id="page" className={styles.container}>
          {template === 'stockholm2' && <Stockholm2 data={state} />}
          {template === 'newyork2' && <Newyork2 data={state} />}
          {template === 'vienna2' && <Vienna2 data={state} />}
          {template === 'sydney2' && <Sydney2 data={state} />}
          {template === 'london2' && <London2 data={state} />}
          {template === 'dublin2' && <Dublin2 data={state} />}
        </div>
      </div>
      <div className={styles.bottom_bar}>
        <button
          id="zoomin"
          className={`${styles.btnzoom}`}
          onClick={handleZoomIn}
        >
          <AiOutlineZoomIn size={20} className={styles.zoom_icon} />
        </button>
        <button
          id="zoomout"
          className={`${styles.btnzoom}`}
          onClick={handleZoomOut}
        >
          <AiOutlineZoomOut size={20} className={styles.zoom_icon} />
        </button>
      </div>
    </>
  );
};

export default memo(CLArtboard);
