import { IoMdBriefcase, IoMdDocument } from 'react-icons/io';
import { MdPerson } from 'react-icons/md';

export default [
  {
    id: 'personalDetails',
    icon: MdPerson,
    fixed: true,
  },
  {
    id: 'companyDetails',
    icon: IoMdBriefcase,
  },
  {
    id: 'letterDetails',
    icon: IoMdDocument,
  },
];
