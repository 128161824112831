import React, { memo } from 'react';
import ContactA from './blocks/Contact/ContactA';
import HeadingA from './blocks/Heading/HeadingA';
import ObjectiveA from './blocks/Objective/ObjectiveA';
import PageContext from '../contexts/PageContext';

const Stockholm2 = ({ data }) => (
  <PageContext.Provider value={{ data, heading: HeadingA }}>
    <div
      id="page"
      className="p-8 rounded"
      style={{
        fontFamily: data.metadata.font,
        color: data.metadata.colors.text,
        backgroundColor: data.metadata.colors.background,
      }}
    >
      <div className="mb-12">
        <h1 className="text-3xl font-bold leading-tight">
          {data.personaldetails.firstName} {data.personaldetails.lastName}
        </h1>
        <div className="text-sm font-medium tracking-wide">
          {data.personaldetails.subtitle}
        </div>
      </div>
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-8">
          <div className="grid gap-4">
            {data.letterdetails.body && <ObjectiveA />}
          </div>
        </div>
        <div className="col-span-4">
          <div
            className="rounded flex flex-col justify-center"
            style={{
              backgroundColor: data.metadata.colors.background,
              color: data.metadata.colors.text,
            }}
          >
            <div className="flex flex-col justify-center">
              <div className="text-sm font-medium tracking-wide mt-5">
                <strong>To</strong>
              </div>
              {data.companydetails.managerName && (
                <span className="text-sm">
                  {data.companydetails.managerName}
                </span>
              )}
              {data.companydetails.companyName && (
                <span className="text-sm">
                  {data.companydetails.companyName}
                </span>
              )}

              <div className="text-sm font-medium tracking-wide mt-5">
                <strong>From</strong>
              </div>

              <ContactA />
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageContext.Provider>
);

export default memo(Stockholm2);
