import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import Heading from '../../../shared/Heading';
import ClInput from '../../../shared/ClInput';

const PersonalDetails = ({ id }) => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="flex justify-between pt-3">
        <Heading id={id} />
      </div>

      <p>
        Double your chances for an interview! Resumes with filled in contact
        details, job role, email and other info usually receiving much more
        views.
      </p>

      <ClInput
        name="heading"
        label={t('builder.sections.heading')}
        path={`${id}.heading`}
      />

      <div className="grid grid-cols-2 gap-6">
        <ClInput
          name="firstName"
          label={t('builder.profile.firstName')}
          path="personaldetails.firstName"
        />
        <ClInput
          name="lastName"
          label={t('builder.profile.lastName')}
          path="personaldetails.lastName"
        />
      </div>

      <ClInput
        name="subtitle"
        label={t('shared.forms.subtitle')}
        path="personaldetails.subtitle"
      />

      <hr />

      <ClInput
        name="addressLine1"
        label={t('builder.profile.address.line1')}
        path="personaldetails.address.line1"
      />

      <hr />

      <ClInput
        name="email"
        label={t('shared.forms.email')}
        path="personaldetails.email"
      />

      <ClInput
        name="phone"
        label={t('shared.forms.phone')}
        path="personaldetails.phone"
      />
    </section>
  );
};

export default memo(PersonalDetails);
