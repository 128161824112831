import React, { memo, useContext } from 'react';
import { safetyCheck } from '../../../utils';
import PageContext from '../../../contexts/PageContext';

const ObjectiveA = () => {
  const { data } = useContext(PageContext);

  return (
    safetyCheck(data.letterdetails, 'body') && (
      <div
        className="text-sm"
        dangerouslySetInnerHTML={{ __html: data.letterdetails.body }}
      />
    )
  );
};

export default memo(ObjectiveA);
