/* eslint-disable jsx-a11y/control-has-associated-label */
import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import * as styles from './Colors.module.css';
import { handleKeyUp } from '../../../../utils';
import { useDispatch } from '../../../../contexts/ResumeContext';
import Heading from '../../../shared/Heading';
import ClInput from '../../../shared/ClInput';
import Button from '../../../shared/Button';
import colorOptions from '../../../../data/colorOptions';

const Colors = ({ id, closeClassName, onClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = (value) => {
    dispatch({
      type: 'on_input2',
      payload: {
        path: 'metadata.colors.primary',
        value,
      },
    });
  };

  return (
    <section>
      <div className="flex justify-between pt-3">
        <Heading id={id} />
        <Button outline className={closeClassName} onClick={onClick}>
          Close
        </Button>
      </div>

      <div className="mb-6 grid grid-cols-8 gap-x-2 gap-y-6">
        {colorOptions.map((color) => (
          <div
            key={color}
            tabIndex="0"
            role="button"
            className={styles.circle}
            style={{ backgroundColor: color }}
            onKeyUp={(e) => handleKeyUp(e, () => handleClick(color))}
            onClick={() => handleClick(color)}
          />
        ))}
      </div>

      <ClInput
        type="color"
        name="primary"
        label={t('builder.colors.primary')}
        placeholder="#FF4081"
        path="metadata.colors.primary"
      />

      <ClInput
        type="color"
        name="text"
        label={t('builder.colors.text')}
        placeholder="#444444"
        path="metadata.colors.text"
      />

      <ClInput
        type="color"
        name="background"
        label={t('builder.colors.background')}
        placeholder="#FFFFFF"
        path="metadata.colors.background"
      />
    </section>
  );
};

export default memo(Colors);
