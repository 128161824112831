import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import { useDispatch } from '../../../../contexts/ResumeContext';
import Heading from '../../../shared/Heading';
import ClInput from '../../../shared/ClInput';

const LetterDetails = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onChange = (content) => {
    dispatch({
      type: 'on_input2',
      payload: {
        path: 'letterdetails.body',
        value: content,
      },
    });
  };

  return (
    <section style={{ marginBottom: '100px' }}>
      <div className="flex justify-between pt-3">
        <Heading id={id} />
      </div>

      <p>
        3–4 paragraphs explaining why you&apos;re the perfect candidate for a
        specific job
      </p>

      <ClInput
        name="heading"
        label={t('builder.sections.heading')}
        path={`${id}.heading`}
      />

      <ClInput
        type="textarea"
        label={t('shared.forms.summary')}
        path="letterdetails.body"
        onChange={onChange}
        placeholder="e.g. Passionate science teacher with 8+ years of experience and a track record of ..."
      />
    </section>
  );
};

export default memo(LetterDetails);
