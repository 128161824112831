import React from 'react';
import ContactE from './blocks/Contact/ContactE';
import HeadingB from './blocks/Heading/HeadingB';
import ObjectiveA from './blocks/Objective/ObjectiveA';
import PageContext from '../contexts/PageContext';

const Newyork2 = ({ data }) => (
  <PageContext.Provider value={{ data, heading: HeadingB }}>
    <div
      id="page"
      className="p-8 rounded"
      style={{
        fontFamily: data.metadata.font,
        color: data.metadata.colors.text,
        backgroundColor: data.metadata.colors.background,
      }}
    >
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-4">
          <div
            className="rounded flex flex-col justify-center"
            style={{
              backgroundColor: data.metadata.colors.background,
              color: data.metadata.colors.text,
            }}
          >
            <div className="flex flex-col justify-center text-right">
              <h1 className="text-3xl font-bold leading-tight">
                {data.personaldetails.firstName} {data.profile.lastName}
              </h1>
              <div className="text-sm font-medium tracking-wide">
                {data.personaldetails.subtitle}
              </div>

              <div className="text-sm font-medium tracking-wide mt-12">
                <strong>To</strong>
              </div>
              {data.companydetails.managerName && (
                <span className="text-sm">
                  {data.companydetails.managerName}
                </span>
              )}
              {data.companydetails.companyName && (
                <span className="text-sm">
                  {data.companydetails.companyName}
                </span>
              )}

              <div className="text-sm font-medium tracking-wide mt-5">
                <strong>From</strong>
              </div>
              <ContactE />
            </div>
          </div>
        </div>
        <div
          className="col-span-1"
          style={{
            borderLeft: '2px solid #777777',
            height: '280mm',
          }}
        />
        <div className="col-span-7">
          <div className="grid gap-4">
            <ObjectiveA />
          </div>
        </div>
      </div>
    </div>
  </PageContext.Provider>
);

export default Newyork2;
