import React, { memo } from 'react';
import { FaCircle } from 'react-icons/fa';
import HeadingA from './blocks/Heading/HeadingA';
import ObjectiveA from './blocks/Objective/ObjectiveA';
import PageContext from '../contexts/PageContext';

const Dublin2 = ({ data }) => (
  <PageContext.Provider value={{ data, heading: HeadingA }}>
    <div
      id="page"
      className="p-8 rounded"
      style={{
        fontFamily: data.metadata.font,
        color: data.metadata.colors.text,
        backgroundColor: data.metadata.colors.background,
      }}
    >
      <div className="mb-10">
        <h1
          className="text-3xl font-bold leading-tight"
          style={{
            borderBottom: '2px solid #777777',
          }}
        >
          {data.personaldetails.firstName} {data.personaldetails.lastName}
        </h1>
        <div className="text-sm font-medium tracking-wide mt-2 d-flex">
          <span className="flex text-sm items-center">
            {data.personaldetails.address.line1}&nbsp;&nbsp;
            <FaCircle size={7} />
            &nbsp;&nbsp;
            {data.personaldetails.email}&nbsp;&nbsp;
            <FaCircle size={7} />
            &nbsp;&nbsp;
            {data.personaldetails.phone}
          </span>
        </div>
      </div>
      <div className="text-sm font-medium tracking-wide mb-5">
        {data.companydetails.companyName && (
          <span className="text-sm">{data.companydetails.managerName}</span>
        )}
        <br />
        {data.companydetails.managerName && (
          <span className="text-sm">{data.companydetails.companyName}</span>
        )}
      </div>

      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-8">
          <div className="grid gap-4">
            {data.letterdetails.body && <ObjectiveA />}
          </div>
        </div>
      </div>
    </div>
  </PageContext.Provider>
);

export default memo(Dublin2);
