const templateOptions = [
  {
    id: 'stockholm2',
    name: 'Stockholm',
  },
  {
    id: 'newyork2',
    name: 'New York',
  },
  {
    id: 'vienna2',
    name: 'Vienna',
  },
  {
    id: 'sydney2',
    name: 'Sydney',
  },
  {
    id: 'london2',
    name: 'London',
  },
  {
    id: 'dublin2',
    name: 'Dublin',
  },
  /* {
    id: 'moscow2',
    name: 'Moscow',
  },
  {
    id: 'amsterdam2',
    name: 'Amsterdam',
  },
  {
    id: 'madrid2',
    name: 'Madrid',
  },
  {
    id: 'santiago2',
    name: 'Santiago',
  }, */
];

export default templateOptions;
